<template>
  <el-main>
    <div class="search">
      <div class="left">
        <span>隔离人员反馈</span>
      </div>
      <el-form class="right" label-width="80px">
        <el-form-item label="关键字：">
          <el-input v-model="keywords" placeholder="请输入搜索..."></el-input>
        </el-form-item>
        <el-button type="primary" @click="getList">搜索</el-button>
        <el-button @click="cancelSearch">清除搜索</el-button>
      </el-form>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="left">
          <div>
            <el-tag
              effect="dark"
              type="primary"
              v-show="item.equipment_number"
              >{{ item.equipment_number }}</el-tag
            >
            <span>【姓名】{{item.user_profile.name}}</span>
            <span>【房间号】{{item.user_profile.room_num}}</span>
            <span>【手机号】{{item.user_profile.mobile}}</span>
            <span>【隔离点】{{item.user_profile.isolation_point }}</span>
          </div>
          <div>
            <p>{{item.title}}</p>
            <p>{{ item.content }}</p>
          </div>
        </div>
        <div class="right" v-for="(images, y) in item.imagess" :key="y">
          <el-image :src="images"></el-image>
        </div>
      </div>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="rows"
      :page-sizes="[5, 10, 15, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total_number"
    >
    </el-pagination>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      rows: 5,
      total_number: 0,
      list: [],
      keywords: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios
        .post(this.$api.category.list, {
          page: this.page,
          rows: this.rows,
          keywords: this.keywords,
        })
        .then((res) => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.list.map((item) => {
              if (item.equipment_number == "0005042940") {
                console.log(item);
              }
            });
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    cancelSearch() {
      this.keywords = "";
      this.getList();
    },
    handleSizeChange(val) {
      this.rows = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  color: #333;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .search {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .left {
      font-weight: bold;
      font-size: 18px;
      span:nth-child(2) {
        margin-left: 4px;
        color: #1e92ff;
      }
    }
    .right {
      display: flex;
      .el-form-item {
        margin: 0;
      }
      .el-input {
        width: 270px;
        margin-right: 20px;
      }

      .el-button {
        border-radius: 19px;
      }
      .el-button--default {
        border-color: #409eff;
      }
    }
  }
  .list {
    width: 100%;
    .item {
      margin-bottom: 20px;
      min-height: 120px;
      border: 1px solid #e0e0e0;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 40px 10px 20px;
      .left div {
        &:nth-child(1){
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          .el-tag{
            margin-right: 5px;
          }
          span{
            margin-right:5px;
          }
        }
        &:nth-child(2){
          p:nth-child(1){
            margin-bottom: 10px;
            font-weight: 600;
          }
        }
      }
      .right {
        margin-left:10px;
        display: flex;
        .el-image {
          margin-right: 10px;
          width: 72px;
          height: 72px;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
